import React, { useEffect, useRef } from 'react';
import clone from 'clone';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ITEM_INFO, ITEM_FORM_TEMPLATE } from './item.template';
import { BEGINNING_STOCK_INFO, BEGINNING_STOCK_FORM_TEMPLATE } from '../../beginning-stock/beginning-stock-form/beginning-stock.template';
import { STOCK_ADJUSTMENT_INFO, STOCK_ADJUSTMENT_FORM_TEMPLATE } from '../../stock-adjustment/stock-adjustment-form/stock-adjustment.template';
import { BILL_OF_MATERIAL_INFO, BILL_OF_MATERIAL_FORM_TEMPLATE } from '../../bill-of-material/bill-of-material-form/bill-of-material.template';
import { ITEM_HISTORY_INFO } from '../../item-history/item-history.template';
import { ITEM_HISTORY_COLUMN_TEMPLATE } from '../../item-history/item-history.template';

import { ITEM_DOCUMENT_DROP_DOWN_ITEMS } from './item-documents-nav.template';

import FormContainer from '../../../components/form/form.container';
import { selectFormSideBarNavActiveItem, selectFormTableLatestData, selectFormTableLatestRows, selectFormTableRows, selectFormTemplate, selectSelectedField } from '../../../redux/form/form.reselect';
import { resetErrorMessageAndIsFail, setEditDataForm, setFormChangedValues, setFormEdit, setFormInfo, setFormProgress, setFormSideBarNav, setFormSideBarNavActiveItem, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFormTemplate, setFormTotalValues, setFormType, setFromValidationError, setSelectedFieldValue } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { removeEmptyValuedObjects } from '../../../utils/object-values.util';
import { removeObjectWithoutSpecifiedPropertiesHasValue } from '../../../utils/array-values.utils';
import { formatNumberToTwoDecimal, roundNumberTwoDecimal, stringToNumber, stringToNumberWithTwoDecmialPoint } from '../../../utils/format-number.util';
import { withRouter } from 'react-router-dom';
import useFetchEdit from '../../../effects/use-fetch-edit.effect';
import { authTokenSelector, selectAccessRights } from '../../../redux/user/user.reselect';
import { selectIsRefresh } from '../../../redux/refresh/refresh.reselect';
import { setDocumentDropDownItems } from '../../../redux/document/document.actions';
import useFetchFieldValues from '../../../effects/use-fetch-field-values';
import { disableFormFields } from '../../../utils/form-values.utils';

import { FaBox } from "react-icons/fa6";
import { MdInventory } from "react-icons/md";
import { FaBoxOpen } from "react-icons/fa";
import { LuRefreshCcwDot } from "react-icons/lu";
import { FaBoxes } from "react-icons/fa";
import { LuHistory } from "react-icons/lu";
import { setIsItemHistory, setItemHistoryNavNumberTemp, setLoadedData, setLocationFilter, setPaymentMethod, setPaymentStatus, setReportDateRange, setReportInfo, setReportSideBarNavActiveItem } from '../../../redux/report/report.actions';
import { selectIsItemHistory, selectItemHistoryNavNumberTemp } from '../../../redux/report/report.reselect';
import { setFilterEmpty } from '../../../redux/filter/filter.actions';

const APP_NAME = `item`;

const SUB_ITEMS = [
    {
        text: "Product",
        icon: MdInventory,
        info: ITEM_INFO,
        formTemplate: ITEM_FORM_TEMPLATE
    },
    {
        text: "Beginning Stock",
        icon: FaBoxOpen,
        info: BEGINNING_STOCK_INFO,
        formTemplate: BEGINNING_STOCK_FORM_TEMPLATE
    },
    {
        text: "Bill of Materials",
        icon: FaBoxes,
        info: BILL_OF_MATERIAL_INFO,
        formTemplate: BILL_OF_MATERIAL_FORM_TEMPLATE
    },
    {
        text: "Product History",
        icon: LuHistory,
        info: ITEM_HISTORY_INFO,
        formTemplate: ITEM_FORM_TEMPLATE,
        reportColumn: ITEM_HISTORY_COLUMN_TEMPLATE
    }
]

const ItemForm = ({
    match,
    setFormInfo,
    setFormTemplate,
    setFormEdit,
    setFormTableData,
    setFormTableLatestRows,
    formTableLatestRows,
    setFormTotalValues,
    setFormTableLatestData,
    formLatestData,
    authToken,
    setFormChangedValues,
    setEditDataForm,
    isNew,
    isRefresh,
    selectedField,
    setSelectedFieldValue,
    setDocumentDropDownItems,
    setFormSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    setFormProgress,
    setFromValidationError,
    setReportInfo,
    resetErrorMessageAndIsFail,
    setItemHistoryNavNumberTemp,
    itemHistoryNavNumberTemp,
    setIsItemHistory,
    isItemHistory,
    setLoadedData,
    setLocationFilter,
    setPaymentMethod,
    setPaymentStatus,
    setReportDateRange,
    setReportSideBarNavActiveItem,
    setFilterEmpty,
    accessRights
}) => {
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);

    const endpoint = isNew ?
        `${SUB_ITEMS[formSideBarNavActiveItem].info.path}` :
        !formSideBarNavActiveItem ?
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}` :
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;


    const authTokenFromState = authToken;
    const { data, error } = useFetchEdit(
        endpoint,
        authTokenFromState,
        !isNew,
        true,
        isRefresh,
        formSideBarNavActiveItem,
        SUB_ITEMS[formSideBarNavActiveItem].info.report
    );

    const reportEndpoint = `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;

    const fieldEndpoint = `/itemvalues`;
    const { fieldData, fieldError } = useFetchFieldValues(
        fieldEndpoint,
        authTokenFromState,
        selectedField,
        isNew
    )

    const TABLE_DATA = [{
        id: 0
    }];

    const getTotalQty = (latestFormRows, keys) => {
        const hasNumerLatestFormRows =
            removeObjectWithoutSpecifiedPropertiesHasValue(latestFormRows, keys);

        return (
            formatNumberToTwoDecimal(hasNumerLatestFormRows.reduce((prevResult, row) => {
                return (
                    prevResult + (stringToNumber(row[keys[0]]))
                )
            }, 0))
        )
    }
    const getTotalAvaialbe = (latestFormRows, keys) => {
        const hasNumerLatestFormRows =
            removeObjectWithoutSpecifiedPropertiesHasValue(latestFormRows, keys);

        return (
            formatNumberToTwoDecimal(hasNumerLatestFormRows.reduce((prevResult, row) => {
                return (
                    prevResult + (stringToNumber(row[keys[0]]))
                )
            }, 0))
        )
    }

    const getTotalKeys = (formSideBarNavActiveItem) => {
        switch (formSideBarNavActiveItem) {
            case 0:
                return ["qtyOnHand"];
            case 1:
                return ["beginningQty"]
        }
    }

    useEffect(() => {
        if (formTableLatestRows.length && (
            formSideBarNavActiveItem === 0 ||
            formSideBarNavActiveItem === 1
        )) {
            const latestFormRows = removeEmptyValuedObjects(formTableLatestRows);
            const totalKeys = getTotalKeys(formSideBarNavActiveItem)
            const totalQuantityOnHand = getTotalQty(latestFormRows, totalKeys);
            const totalAvailable = totalKeys[0] === "qtyOnHand" &&
                getTotalAvaialbe(latestFormRows, ["available"]);

            const totalValues = {
                totalQuantityOnHand,
                totalAvailable
            };

            setFormTotalValues(totalValues);
        }
    }, [formTableLatestRows, formLatestData]);

    useEffect(() => {
        if (selectedField) {
            const selectedFieldValue = {
                name: selectedField.name,
                value: fieldData
            }

            setSelectedFieldValue(selectedFieldValue)
        }
    }, [fieldData])

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }
        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        setFormInfo(SUB_ITEMS[formSideBarNavActiveItem].info);

        const formTemplate = clone(SUB_ITEMS[formSideBarNavActiveItem].formTemplate);

        if (isViewOnly.current && Object.keys(formTemplate).length) {
            const bodySections = formTemplate.bodySections;
            disableFormFields(bodySections)
            bodySections.tableForm.hasNoAction = true;
            formSideBarNavActiveItem !== 0 && delete bodySections.tableForm.tableColumns[bodySections.tableForm.tableColumns.length - 1];
        }

        setFormTemplate(formTemplate);
        setDocumentDropDownItems(ITEM_DOCUMENT_DROP_DOWN_ITEMS);
        setFormTableData(TABLE_DATA);

        if (!isNew) {
            setFormSideBarNav(SUB_ITEMS);
            setFormEdit(true);
            setFromValidationError(null);
            resetErrorMessageAndIsFail();
        }
    }, [formSideBarNavActiveItem, isViewOnly]);


    useEffect(() => {
        setReportInfo(SUB_ITEMS[formSideBarNavActiveItem].info);
    }, [formSideBarNavActiveItem]);

    useEffect(() => {
        setEditDataForm(data);
    }, [data]);

    const [confirmIsItemHisotrySetAndReportRest, setConfirmIsItemHisotrySetAndReportRest] = useState(false);

    useEffect(() => {
        if (!isItemHistory && formSideBarNavActiveItem === (SUB_ITEMS.length - 1)) {
            setLoadedData({
                data: null,
                skip: null,
                totalNumItems: 0
            })

            setIsItemHistory(true)
            setConfirmIsItemHisotrySetAndReportRest(true)
        } else {
            setIsItemHistory(false)
        }


    }, [formSideBarNavActiveItem])

    useEffect(() => {
        if (isItemHistory) {
            setFormSideBarNavActiveItem(SUB_ITEMS.length - 1)
        }
    }, [isItemHistory])

    useEffect(() => {
        return () => {
            setFormInfo(null);
            setFormTemplate(null);
            setDocumentDropDownItems(null);
            setFormTableData(null);
            setFormEdit(false)
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setEditDataForm([]);
            setSelectedFieldValue(null);
            setFormSideBarNav([]);
            setFormSideBarNavActiveItem(0);
        }
    }, [])

    return (
        <div>
            {isAccessRightChecked &&
                (isItemHistory ? formSideBarNavActiveItem === (SUB_ITEMS.length - 1) : true) &&
                Object.keys(ITEM_FORM_TEMPLATE).length > 0 ?
                !SUB_ITEMS[formSideBarNavActiveItem].info.report ?
                    <FormContainer
                        endpoint={endpoint}
                        isViewOnly={isViewOnly.current}
                    /> :
                    confirmIsItemHisotrySetAndReportRest &&
                    <FormContainer
                        showReport={true}
                        reportColumn={SUB_ITEMS[formSideBarNavActiveItem].reportColumn}
                        isViewOnly={isViewOnly.current}
                        reportEndpoint={reportEndpoint}
                        dataKeys={[]}
                        hasNoDate={true}
                    />
                : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatestRows: selectFormTableLatestRows,
    formLatestData: selectFormTableLatestData,
    authToken: authTokenSelector,
    isRefresh: selectIsRefresh,
    selectedField: selectSelectedField,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    accessRights: selectAccessRights,
    itemHistoryNavNumberTemp: selectItemHistoryNavNumberTemp,
    isItemHistory: selectIsItemHistory
})

const mapDispatchToProps = (disptach) => ({
    setFormInfo: (formInfo) =>
        disptach(setFormInfo(formInfo)),
    setFormTemplate: (formTemplate) =>
        disptach(setFormTemplate(formTemplate)),
    setDocumentDropDownItems: (dropDownItems) =>
        disptach(setDocumentDropDownItems(dropDownItems)),
    setFormType: (modalType) =>
        disptach(setFormType(modalType)),
    setFormTableData: (tableData) =>
        disptach(setFormTableData(tableData)),
    setFormTotalValues: (tableData) =>
        disptach(setFormTotalValues(tableData)),
    setFormChangedValues: (data) =>
        disptach(setFormChangedValues(data)),
    setFormEdit: (data) =>
        disptach(setFormEdit(data)),
    setEditDataForm: (data) =>
        disptach(setEditDataForm(data)),
    setFormTableLatestRows: (data) =>
        disptach(setFormTableLatestRows(data)),
    setFormTableLatestData: (data) =>
        disptach(setFormTableLatestData(data)),
    setSelectedFieldValue: (data) =>
        disptach(setSelectedFieldValue(data)),
    setFormSideBarNav: (data) =>
        disptach(setFormSideBarNav(data)),
    setFormSideBarNavActiveItem: (data) =>
        disptach(setFormSideBarNavActiveItem(data)),
    setFormProgress: (data) =>
        disptach(setFormProgress(data)),
    setFromValidationError: (errors) =>
        disptach(setFromValidationError(errors)),
    resetErrorMessageAndIsFail: () =>
        disptach(resetErrorMessageAndIsFail()),
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setItemHistoryNavNumberTemp: (data) =>
        disptach(setItemHistoryNavNumberTemp(data)),
    setIsItemHistory: (data) =>
        disptach(setIsItemHistory(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),
    setLoadedData: (data) =>
        disptach(setLoadedData(data)),
    setPaymentMethod: (data) =>
        disptach(setPaymentMethod(data)),
    setPaymentStatus: (data) =>
        disptach(setPaymentStatus(data)),
    setLocationFilter: (data) =>
        disptach(setLocationFilter(data)),
    setFilterEmpty: (data) =>
        disptach(setFilterEmpty(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemForm));