import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

import { useMediaQuery } from 'react-responsive';

export const WORK_ORDER_DEFECT_RECOVERY_INFO = {
  formTitle: "Manufacture Order",
  path: "/workorders",
  subPath: "/defectrecovery",
  listPage: "/work-orders",

}

export const WORK_ORDER_DEFECT_RECOVERY_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "workOrder",
      fieldItems: [
        {
          label: "MO Number",
          type: "text",
          name: "workOrderNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `WO-`
        },
        {
          label: "Branch",
          type: "select",
          name: "Location",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          disabled: true,
          validationProps: {
            required: "Location is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   muliselect: false,
        //   url: "/users"
        // },
        {
          label: "MO Date",
          type: "date",
          name: "workOrderDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "WO date is required"
          }
        },
        {
          label: "Finish Date",
          type: "date",
          name: "finishDate",
          disabled: true,
          initialFocus: false,
        }
      ]
    },
    finishedProductDetails: {
      sectionType: "Finished Product details",
      sectionTypeId: "finishedProductDetails",
      multiForm: false,
      expandable: true,
      isExpand: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "finishedProduct",
      fieldItems: [
        {
          label: "Product",
          type: "select",
          name: "Item",
          initialFocus: false,
          muliselect: false,
          autofiller: true,
          url: "/items",
          disabled: true,
          validationProps: {
            required: "Item is required"
          }
        },
        {
          label: "Defect Quantity",
          type: "text",
          name: "defectQty",
          initialFocus: false,
          disabled: false,
        },
      ]
    },
    tableForm: {
      sectionType: "Raw materials",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: true,
      isDisable: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Component Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              disabled={row.isDisable}
              noAvailability={true}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 80,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Qty Recovered"}
                required={true}
              />
            )
          },
          accessor: "qtyRecovered",
          width: 80,
          Cell: ({ row }) => {
    

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qtyRecovered"}
              rowNumber={row.id}
              valueType={"number"}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Condition"}
                required={true}
              />
            )
          },
          accessor: "Condition",
          width: 160,
          Cell: ({ row }) => {
            const endpointUrl = "/recovercondition";

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Condition"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Recovered Date"}
                required={true}
              />
            )
          },
          accessor: "recoveredDate",
          width: 80,
          Cell: ({ row }) => {
        

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"recoveredDate"}
              rowNumber={row.id}
              value={row.value}
              disabled={row.isDisable}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: [
    "finishedProduct.0.defectQty",
  ]
}
