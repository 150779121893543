import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const ACCOUNT_INFO = {
  formTitle: "Account",
  formTitleForNew: "New Account",
  path: "/users",
  listPage: "/accounts",
}

export const ACCOUNT_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 4,
      fieldName: "account",
      fieldItems: [
        {
          label: "Username",
          type: "text",
          name: "username",
          initialFocus: false,
          validationProps: {
            required: "Username is required",
            minLength: {
              value: 4,
              message: "Username must be more than 4 letters"
            }
          }
        },
        {
          label: "First Name",
          type: "text",
          name: "firstName",
          initialFocus: false,
          validationProps: {
            required: "First Name is required"
          }
        },
        {
          label: "Last Name",
          type: "text",
          name: "lastName",
          initialFocus: false,
          validationProps: {
            required: "Last Name is required"
          }
        },
        {
          label: "Email",
          type: "email",
          name: "email",
          initialFocus: false,
          validationProps: {
            //required: "Email Address is required",
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required',
            }
          }
        },
        
        {
          label: "Phone Number",
          type: "text",
          name: "phoneNumber",
          initialFocus: false,
          // validationProps: {
          //   required: "Phone Number is required"
          // }
        },
        {
          label: "Role",
          type: "select",
          name: "Role",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Administrator', value: 1 },
          url: "/userroles",
          validationProps: {
            required: "Role is required"
          }
        },
        {
          label: "Password",
          type: "password",
          name: "password",
          initialFocus: false,
          isNoEditValidation: true,
          validationProps: {
            required: "Password is required",
            minLength: {
              value: 4,
              message: "Password must be more than 4 letters"
            }
          }
        }
      ]
    },
    approvalRightsDetails: {
      sectionType: "Approval Rights",
      sectionTypeId: "approvalPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 3,
      fieldName: "approvalPermission",
      fieldItems: [
        {
          label: "Sales Orders",
          type: "select",
          name: "salesOrderApproval",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Non Approver', value: 2 },
          url: "/approvalpermissions"
        },
        {
          label: "Purchase Orders",
          type: "select",
          name: "purchaseOrderApproval",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Non Approver', value: 2 },
          url: "/approvalpermissions"
        },
        {
          label: "Stock Transfers",
          type: "select",
          name: "stockTransferApproval",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Non Approver', value: 2 },
          url: "/approvalpermissions"
        },
        {
          label: "Stock Adjustment",
          type: "select",
          name: "stockAdjustmentApproval",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Non Approver', value: 2 },
          url: "/approvalpermissions"
        },
        {
          label: "Stock Count",
          type: "select",
          name: "stockCountApproval",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Non Approver', value: 2 },
          url: "/approvalpermissions"
        },
        {
          label: "Manufacture Order",
          type: "select",
          name: "workOrderApproval",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Non Approver', value: 2 },
          url: "/approvalpermissions"
        },
      ]
    },
    locationPermissionDetails: {
      sectionType: "Branch Access Rights",
      sectionTypeId: "locationPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "locationPermission",
      fieldItems: [
        {
          label: "Branches",
          type: "select",
          name: "location",
          required: false,
          initialFocus: false,
          mutliselect: true,
          isAccount: true,
          // defaultValue: { label: 'Full Access', value: 1 },
          url: "/locations"
        },
      ]
    },
    salesPermissionDetails: {
      sectionType: "Sales Access Rights",
      sectionTypeId: "salesPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 2,
      fieldName: "salesPermission",
      fieldItems: [
        {
          label: "Sales Orders",
          type: "select",
          name: "salesOrder",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Customers",
          type: "select",
          name: "customer",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Sales Quotes",
          type: "select",
          name: "salesQuote",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
      ]
    },
    purchasePermissionDetails: {
      sectionType: "Purchasing Access Rights",
      sectionTypeId: "purchasingPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 2,
      fieldName: "purchasingPermission",
      fieldItems: [
        {
          label: "Purchase Orders",
          type: "select",
          name: "purchaseOrder",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Suppliers",
          type: "select",
          name: "supplier",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Operating Expense",
          type: "select",
          name: "operatingExpense",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Expense Type",
          type: "select",
          name: "expenseType",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
      ]
    },
    inventoryPermissionDetails: {
      sectionType: "Stock Access Rights",
      sectionTypeId: "inventoryPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 5,
      fieldName: "inventoryPermission",
      fieldItems: [
        {
          label: "Products",
          type: "select",
          name: "item",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Categories",
          type: "select",
          name: "itemGroup",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Stock Tranfers",
          type: "select",
          name: "stockTransfer",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Stock Adjustment",
          type: "select",
          name: "stockAdjustment",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Stock Count",
          type: "select",
          name: "stockCount",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Unit of Measures",
          type: "select",
          name: "unitOfMeasure",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Branches",
          type: "select",
          name: "location",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Item Taxes",
          type: "select",
          name: "itemTax",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Manufacture Order",
          type: "select",
          name: "workOrder",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        }
      ]
    },
    reportPermissionDetails: {
      sectionType: "Report Access Rights",
      sectionTypeId: "reportPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 2,
      fieldName: "reportPermission",
      fieldItems: [
        {
          label: "Reports",
          type: "select",
          name: "report",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        }
      ]
    },
    systemPermissionDetails: {
      sectionType: "System Access Rights",
      sectionTypeId: "systemPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "systemPermission",
      fieldItems: [
        {
          label: "Accounts",
          type: "select",
          name: "account",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        },
        {
          label: "Company",
          type: "select",
          name: "company",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Full Access', value: 1 },
          url: "/permissions"
        }
      ]
    },
  },
  watchFields: []
}
