import React, { useEffect, useRef } from 'react';
import clone from 'clone';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { WORK_ORDER_INFO, WORK_ORDER_FORM_TEMPLATE } from './work-order.template';
import { WORK_ORDER_PICKING_INFO, WORK_ORDER_PICKING_FORM_TEMPLATE } from '../../work-order-picking/work-order-picking-form/work-order-picking.template';
import { WORK_ORDER_DEFECT_RECOVERY_INFO, WORK_ORDER_DEFECT_RECOVERY_FORM_TEMPLATE } from '../../work-order-defect-recovery/work-order-defect-recovery-form/work-order-defect-recovery.template';

import { UNSTOCK_INFO, UNSTOCK_FORM_TEMPLATE } from '../../unstock/unstock-form/unstock.template';
import { WORK_ORDER_DOCUMENT_DROP_DOWN_ITEMS } from './work-order-documents-nav.template';

import FormContainer from '../../../components/form/form.container';
import { selectFormSideBarNavActiveItem, selectFormTableLatestData, selectFormTableLatestRows, selectFormTableRows, selectFormTemplate, selectSelectedField } from '../../../redux/form/form.reselect';
import { resetErrorMessageAndIsFail, setAutoFillDataForm, setEditDataForm, setFormChangedValues, setFormEdit, setFormInfo, setFormProgress, setFormSideBarNav, setFormSideBarNavActiveItem, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFormTemplate, setFormTotalValues, setFormType, setFromValidationError, setSelectedField, setSelectedFieldValue, setValueEntredBlur } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { removeEmptyValuedObjects } from '../../../utils/object-values.util';
import { removeObjectWithoutSpecifiedPropertiesHasValue } from '../../../utils/array-values.utils';
import { formatNumberToTwoDecimal, roundNumberTwoDecimal, stringToNumber, stringToNumberWithTwoDecmialPoint } from '../../../utils/format-number.util';
import { withRouter } from 'react-router-dom';
import useFetchEdit from '../../../effects/use-fetch-edit.effect';
import { authTokenSelector, selectAccessRights } from '../../../redux/user/user.reselect';
import { selectIsRefresh } from '../../../redux/refresh/refresh.reselect';
import { setDocumentDropDownItems } from '../../../redux/document/document.actions';
import useFetchFieldValues from '../../../effects/use-fetch-field-values';
import { disableFormFields } from '../../../utils/form-values.utils';
import { cloneDeep } from 'lodash';
import useFetchAutoFill from '../../../effects/use-fetch-autofill';

import { MdFactory } from "react-icons/md";
import { MdForklift } from "react-icons/md";
import { RiRecycleFill } from "react-icons/ri";

const APP_NAME = `work_order`;

const SUB_ITEMS = [
    {
        text: "Manufacture Order",
        icon: MdFactory,
        info: WORK_ORDER_INFO,
        formTemplate: WORK_ORDER_FORM_TEMPLATE
    },
    {
        text: "Full Fill Order",
        icon: MdForklift,
        info: WORK_ORDER_PICKING_INFO,
        formTemplate: WORK_ORDER_PICKING_FORM_TEMPLATE
    },
    {
        text: "Defect & Recovery",
        icon: RiRecycleFill,
        info: WORK_ORDER_DEFECT_RECOVERY_INFO,
        formTemplate: WORK_ORDER_DEFECT_RECOVERY_FORM_TEMPLATE
    },
]

const WorkOrderForm = ({
    match,
    setFormInfo,
    setFormTemplate,
    setFormEdit,
    setFormTableData,
    setFormTableLatestRows,
    formTableLatestRows,
    setFormTotalValues,
    setFormTableLatestData,
    formLatestData,
    authToken,
    setFormChangedValues,
    setEditDataForm,
    setAutoFillDataForm,
    isNew,
    isRefresh,
    selectedField,
    setSelectedFieldValue,
    setSelectedField,
    setDocumentDropDownItems,
    setFormSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    setFormProgress,
    setValueEntredBlur,
    setFromValidationError,
    resetErrorMessageAndIsFail,
    accessRights
}) => {
    const [isSetField, setIsSetField] = useState(false);
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);

    const endpoint = isNew ?
        `${SUB_ITEMS[formSideBarNavActiveItem].info.path}` :
        !formSideBarNavActiveItem ?
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}` :
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;

    const endpointForApprove = `workorders/${match.params.uuid}`

    const authTokenFromState = authToken;
    const { data, error } = useFetchEdit(
        endpoint,
        authTokenFromState,
        !isNew,
        true,
        isRefresh,
        formSideBarNavActiveItem
    );

    const [isAutoFill, setIsAutoFill] = useState(false);
    const [selectedFinishedProduct, setSelectedFinishedProduct] = useState(null);

    const autoFillUrl = `/workorders/finishedproducts/autofill`;
    const { autoFillData, autoFillError, isAutoFillLoading } = useFetchAutoFill(
        autoFillUrl,
        authTokenFromState,
        setIsAutoFill,
        isAutoFill,
        selectedFinishedProduct
    )

    const fieldEndpoint = `/itemvalues`;
    const { fieldData, fieldError } = useFetchFieldValues(
        fieldEndpoint,
        authTokenFromState,
        selectedField,
        isNew
    )

    const TABLE_DATA = [{
        id: 0
    }];

    const getItemCount = (latestFormRows) => {
        return latestFormRows.length;
    }

    const getSubTotal = (latestFormRows, keys) => {
        const hasNumerLatestFormRows =
            removeObjectWithoutSpecifiedPropertiesHasValue(latestFormRows, keys)

        return (
            formatNumberToTwoDecimal(hasNumerLatestFormRows.reduce((prevResult, row) => {
                return (
                    prevResult + (stringToNumber(row[keys[0]]) * stringToNumber(row[keys[1]]))
                )
            }, 0))
        )
    }

    const getSubTotalKeys = (formSideBarNavActiveItem) => {
        switch (formSideBarNavActiveItem) {
            case 0:
                return ["qty", "unitCost"];
            case 2:
                return ["returnQty", "unitCost"]
        }
    }

    useEffect(() => {
        if (formTableLatestRows.length && (
            formSideBarNavActiveItem === 0 ||
            formSideBarNavActiveItem === 2
        )) {
            const latestFormRows = removeEmptyValuedObjects(formTableLatestRows);
            const itemCount = getItemCount(latestFormRows);
            const subTotalKeys = getSubTotalKeys(formSideBarNavActiveItem)
            const subTotal = getSubTotal(latestFormRows, subTotalKeys);

            const totalValues = {
                itemCount,
                subTotal,
            };

            setFormTotalValues(totalValues);
        }
    }, [formTableLatestRows, formLatestData]);

    useEffect(() => {
        if (selectedField) {
            const selectedFieldValue = {
                name: selectedField.name,
                value: fieldData
            }

            setSelectedFieldValue(selectedFieldValue);
            setIsSetField(true);
            setSelectedField(null)
        }
    }, [fieldData]);

    useEffect(() => {
        if (isSetField) {
            setValueEntredBlur(true);
            setIsSetField(false)
        }
    }, [isSetField])

    useEffect(() => {
        let progress;
        if (formSideBarNavActiveItem === 1) {
            const sumup = formTableLatestRows.reduce((prevValue, row) => {
                const qty = prevValue.qty + (row.qty ? parseFloat(row.qty) : 0);
                const qtyReceived = prevValue.qtyReceived + (row.qtyReceived ? parseFloat(row.qtyReceived) : 0);

                return {
                    qty,
                    qtyReceived
                }
            }, { qty: 0, qtyReceived: 0 })

            progress = ((sumup.qtyReceived / sumup.qty) * 100);
        }

        setFormProgress(roundNumberTwoDecimal(progress));
    }, [formTableLatestRows]);

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }
        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        setFormInfo(SUB_ITEMS[formSideBarNavActiveItem].info);

        const formTemplate = clone(SUB_ITEMS[formSideBarNavActiveItem].formTemplate);

        if (isViewOnly.current && Object.keys(formTemplate).length) {
            const bodySections = formTemplate.bodySections;
            disableFormFields(bodySections)
            bodySections.tableForm.hasNoAction = true;
            delete bodySections.tableForm.tableColumns[bodySections.tableForm.tableColumns.length - 1];
        }

        setFormTemplate(formTemplate);
        setDocumentDropDownItems(WORK_ORDER_DOCUMENT_DROP_DOWN_ITEMS);
        setFormTableData(TABLE_DATA);

        if (!isNew) {
            setFormSideBarNav(SUB_ITEMS);
            setFormEdit(true);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFromValidationError(null);
            resetErrorMessageAndIsFail();
        }
    }, [formSideBarNavActiveItem, isViewOnly]);

    useEffect(() => {
        if (Object.keys(formLatestData).length && formLatestData.Item) {
            if (isNew) {
                setSelectedFinishedProduct(formLatestData.Item);
                setIsAutoFill(true)
            }
        }
    }, [formLatestData.Item])

    useEffect(() => {
        setAutoFillDataForm({ items: autoFillData })
    }, [autoFillData])

    useEffect(() => {
        setEditDataForm(data);
    }, [data]);

    useEffect(() => {
        return () => {
            setFormInfo(null);
            setFormTemplate(null);
            setDocumentDropDownItems(null);
            setFormTableData(null);
            setFormEdit(false)
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setAutoFillDataForm(null);
            setEditDataForm([]);
            setSelectedField(null);
            setSelectedFieldValue(null);
            setFormSideBarNav([]);
            setFormSideBarNavActiveItem(0);
        }
    }, [])

    return (
        <div>
            {isAccessRightChecked &&
                Object.keys(WORK_ORDER_FORM_TEMPLATE).length > 0 ?
                <FormContainer
                    endpoint={endpoint}
                    isViewOnly={isViewOnly.current}
                    endpointForApprove={endpointForApprove}
                />
                : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatestRows: selectFormTableLatestRows,
    formLatestData: selectFormTableLatestData,
    authToken: authTokenSelector,
    isRefresh: selectIsRefresh,
    selectedField: selectSelectedField,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    accessRights: selectAccessRights
})

const mapDispatchToProps = (disptach) => ({
    setFormInfo: (formInfo) =>
        disptach(setFormInfo(formInfo)),
    setFormTemplate: (formTemplate) =>
        disptach(setFormTemplate(formTemplate)),
    setDocumentDropDownItems: (dropDownItems) =>
        disptach(setDocumentDropDownItems(dropDownItems)),
    setFormType: (modalType) =>
        disptach(setFormType(modalType)),
    setFormTableData: (tableData) =>
        disptach(setFormTableData(tableData)),
    setFormTotalValues: (tableData) =>
        disptach(setFormTotalValues(tableData)),
    setFormChangedValues: (data) =>
        disptach(setFormChangedValues(data)),
    setFormEdit: (data) =>
        disptach(setFormEdit(data)),
    setEditDataForm: (data) =>
        disptach(setEditDataForm(data)),
    setAutoFillDataForm: (data) =>
        disptach(setAutoFillDataForm(data)),
    setFormTableLatestRows: (data) =>
        disptach(setFormTableLatestRows(data)),
    setFormTableLatestData: (data) =>
        disptach(setFormTableLatestData(data)),
    setSelectedFieldValue: (data) =>
        disptach(setSelectedFieldValue(data)),
    setFormSideBarNav: (data) =>
        disptach(setFormSideBarNav(data)),
    setFormSideBarNavActiveItem: (data) =>
        disptach(setFormSideBarNavActiveItem(data)),
    setFormProgress: (data) =>
        disptach(setFormProgress(data)),
    setValueEntredBlur: (data) =>
        disptach(setValueEntredBlur(data)),
    setSelectedField: (data) =>
        disptach(setSelectedField(data)),
    setFromValidationError: (errors) =>
        disptach(setFromValidationError(errors)),
    resetErrorMessageAndIsFail: () =>
        disptach(resetErrorMessageAndIsFail()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderForm));